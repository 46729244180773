<template>
  <div class="hero min-h-screen bg-base-200 w-full">
    <div class="flex-col justify-center hero-content lg:flex-row w-full">
      <div class="text-center lg:text-left h-full md:w-1/3">
        <h1 class="mb-5 text-5xl font-bold">Cryptrade</h1>
        <p class="mb-5 text-md font-medium">Smart trading platform</p>
      </div>
      <div class="flex-shrink-0 md:w-1/3 w-full">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Auth',
}
</script>
